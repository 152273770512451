// import logo from './logo.svg';
import './App.css';
import Nav from './Nav';
import Footer from './Footer';
import Services from './Services';
import Gallery from './Gallery';
import Contact from './Contact';
import About from './About';
// import Typography from '@mui/material/Typography';
// import Image1 from './home1.jpg';
// import Image2 from './portrait1.jpg';
// import { Box, Container } from '@mui/material';
import GoogleFontLoader from 'react-google-font-loader';
// import * as React from 'react';
import Home from './Home'; // Import Home component

function App() {
  return (
    <>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Roboto',
            weights: [400, 700], // You can specify multiple weights if needed
          },
          {
            font: 'Open Sans',
            weights: [400, 600],
          },
          {
            font: 'Noto Sans',
            weights: [400, 600],
          },
          // Add more fonts as needed
        ]}
      />
      <Nav />
      <div className="App" id="home">
        
        <Home /> {/* Render Home component */}
      </div>
      <div id="services">
        <Services />
      </div>
      <div id="gallery">
        <Gallery />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="contact" className="get-quote">
        <Contact />
      </div>
      <div id="footer">
        <Footer />
      </div>
      
    </>
  );
}

export default App;
