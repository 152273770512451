import React from 'react';
import { Box, Typography, Container, useMediaQuery, Button } from '@mui/material';
import homeImage from './home1.jpg';

const scrollToSection = (sectionId) => {
    const section = document.getElementsByClassName(sectionId)[0];
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      // handleDrawerToggle(); // Close the drawer after scrolling
    }
  };

const Home = () => {
  const isLargeScreen = useMediaQuery('(min-width: 960px)');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isLargeScreen ? 'row' : 'column-reverse',
        minHeight: 'calc(100vh - 64px)', // Adjusting for the fixed navbar
        color: '#1976d2', // Blue text color
        backgroundColor: '#f0f0f0', // Light gray background color
        padding: '64px 20px', // Adjusting for the fixed navbar
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // padding: isLargeScreen ? '0 20px' : '20px', // Adjust padding for smaller screens
        }}
      >
        <Container maxWidth="md">
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontSize: { xs: '28px', sm: '36px', md: '58px' },
              textAlign: 'center',
              marginBottom: '20px',
              fontFamily: 'Noto Sans', 
              fontWeight: 'bold',
            }}
          >
            Ababeel Multiservices Enterprises
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontSize: isLargeScreen ? 'clamp(18px, 4vw, 32px)' : 'clamp(14px, 3vw, 24px)',
              textAlign: 'center',
              fontFamily: 'Noto Sans',
            }}
          >
            ---Turning your imagination into Reality---
          </Typography>
          <Box mt={3}>
            
              <Button onClick={() => scrollToSection('get-quote')} variant="contained" color="primary">
                Get Quotation
              </Button>
          
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={homeImage}
          alt="Home"
          style={{
            maxWidth: isLargeScreen ? '100%' : '90%', // Adjust image width for smaller screens
            height: 'auto',
            borderRadius: '10px', // Rounded corners for the image
          }}
        />
      </Box>
    </Box>
  );
};

export default Home;
