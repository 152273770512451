import React from 'react';
import { Box, Container, Typography, Grid, Button } from '@mui/material';
import aboutImage from './grid/const.jpg'; // Import your about image here

const About = () => {
  return (
    <Box sx={{ backgroundColor: '#f5f5f5', py: 8 }}>
      <Container maxWidth="lg">
        <Typography variant="h2" align="center" gutterBottom sx={{ color: '#1976d2', fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: { xs: '28px', sm: '32px', md: '48px' } }}>
          About Us
        </Typography>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{ color: '#333', fontFamily: 'Noto Sans', fontSize: 20 }}>
              <b>Ababeel Multiservices Enterprises</b>, founded in 2016, stands as a leading <b>General Contractor</b> offering a comprehensive range of services. Specializing in construction and civil work, our expertise extends to interior decoration, metal art fabrication, woodworking, furniture polishing, and maintenance. We also excel in composite sheet fabrication, acoustic panels, glasswork, partitioning, and pop/false ceilings, ensuring unparalleled quality and craftsmanship in every project.
            </Typography>
            {/* <Button variant="contained" sx={{ backgroundColor: '#1976d2', color: '#fff', mt: 4 }}>
              Learn More
            </Button> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={aboutImage} alt="About Us" style={{ width: '100%', borderRadius: 8 }} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default About;
