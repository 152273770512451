import React from 'react';
import { Box, Container, Grid, Typography, TextField, Button, Paper } from '@mui/material';

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
  };

  return (
    <Box sx={{ backgroundColor: '#f9f9f9', py: 8 }}>
      <Container maxWidth="lg">
        <Typography variant="h2" align="center" gutterBottom sx={{ color: '#1976d2', fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: { xs: '28px', sm: '32px', md: '48px' } }}>
          Contact Us
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {/* Contact Form */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 3, backgroundColor: '#fff' }}>
              <Typography variant="h4" gutterBottom sx={{ color: '#1976d2', mb: 3, fontSize: { xs: '24px', sm: '28px', md: '32px' } }}>
                Get in Touch
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField label="Name" variant="outlined" fullWidth margin="normal" />
                <TextField label="Email" variant="outlined" fullWidth margin="normal" />
                <TextField label="Message" variant="outlined" fullWidth multiline rows={4} margin="normal" />
                <Button variant="contained" type="submit" sx={{ backgroundColor: '#1976d2', color: '#fff', mt: 2 }}>
                  Submit
                </Button>
              </form>
            </Paper>
          </Grid>
          {/* Contact Details */}
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 3, backgroundColor: '#fff' }}>
              <Typography variant="h4" gutterBottom sx={{ color: '#1976d2', mb: 3, fontSize: { xs: '24px', sm: '28px', md: '32px' } }}>
                Contact Details
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '16px', sm: '18px', md: '20px' } }}>
                Mobile No.: +919890412410, +919970799323
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '16px', sm: '18px', md: '20px' } }}>
                Email: ababeelmultiservices@gmail.com
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '16px', sm: '18px', md: '20px' } }}>
                Address: Sr. No. 43, Somnath Nagar, Wadgaon Sheri, Pune - 411014
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '16px', sm: '18px', md: '20px' } }}>
                GST No.: 27BTOPS8719L1ZM
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        {/* Location Section */}
        <Box mt={4}>
          <Paper elevation={3} sx={{ p: 3, backgroundColor: '#fff' }}>
            <Typography variant="h4" gutterBottom sx={{ color: '#1976d2', textAlign: 'center', mb: 3, fontSize: { xs: '24px', sm: '28px', md: '32px' } }}>
              Location
            </Typography>
            {/* Place your map component here */}
            <iframe
              title="Location Map"
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3782.3831252881578!2d73.9194633751925!3d18.556758282543846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDMzJzI0LjMiTiA3M8KwNTUnMTkuMyJF!5e0!3m2!1sen!2sin!4v1708592956567!5m2!1sen!2sin"
              width="100%"
              height="350"
              style={{ border: 0, borderRadius: '5px' }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;
