import React, { useState } from 'react';
import { Box, Container, Typography, Grid, Button } from '@mui/material';
import { styled } from '@mui/system';
import ConstImage from './grid/const.jpg';
import GlassImage from './grid/glass.jpg';
import InteriorImage from './grid/interior.jpg';
import MetalImage from './grid/metal.jpg';
import PopImage from './grid/pop.jpg';
import WoodImage from './grid/wood.jpg';
import HomeImage from './grid/home.jpg';

const GalleryItem = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  '&:hover img': {
    transform: 'scale(1.1)',
  },
  '&:hover::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },
}));

const Gallery = () => {
  const initialImages = [
    ConstImage,
    GlassImage,
    InteriorImage,
    MetalImage,
    PopImage,
    WoodImage,
    HomeImage
  ];

  const [showMore, setShowMore] = useState(false);

  return (
    <Box sx={{ backgroundColor: '#f9f9f9', py: 1 }}>
      <Container maxWidth="lg">
        <Typography variant="h2" align="center" gutterBottom sx={{ color: '#1976d2', fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: { xs: '28px', sm: '32px', md: '48px' } }}>
          Gallery
        </Typography>
        <Grid container spacing={3}>
          {initialImages.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <GalleryItem style={{ display: showMore || index < 6 ? 'block' : 'none' }}>
                <img src={image} alt={`Gallery ${index + 1}`} style={{ width: '100%', height: 'auto', transition: 'transform 0.3s ease' }} />
              </GalleryItem>
            </Grid>
          ))}
        </Grid>
        {!showMore && (
          <Box mt={4} sx={{ textAlign: 'center' }}>
            <Button variant="contained" color="primary" onClick={() => setShowMore(true)}>
              View More
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Gallery;
