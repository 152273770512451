import React from 'react';
import { Box, Container, Typography, Grid, Link, IconButton } from '@mui/material';
import { Instagram, Facebook, LinkedIn } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: '#1976d2', color: 'white', py: 4 }}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant="h6" gutterBottom sx={{ textDecoration: 'underline' }}>Contact Us</Typography>
            <Typography gutterBottom>Mobile No.: +919890412410, +919970799323</Typography>
            <Typography gutterBottom>Email: ababeelmultiservices@gmail.com</Typography>
            <Typography gutterBottom>Address: Sr. No. 43, Somnath Nagar, Wadgaon Sheri, Pune - 411014</Typography>
            <Typography gutterBottom>GST No.: 27BTOPS8719L1ZM</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant="h6" gutterBottom sx={{ textDecoration: 'underline' }}>Our Services</Typography>
            <Typography gutterBottom component="ul">
              <li>Construction and Civil Work</li>
              <li>Glass Work and Partition</li>
              <li>Metal Art and Fabrication</li>
              <li>Wood Work and Furnitures</li>
              <li>Interior Decorator</li>
              <li>Pop and False Ceiling</li>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Typography variant="h6" gutterBottom sx={{ textDecoration: 'underline' }}>Follow Us</Typography>
            {/* Social Media Links */}
            <Box>
              <IconButton color="inherit" component={Link} href="#" aria-label="Instagram">
                <Instagram />
              </IconButton>
              <IconButton color="inherit" component={Link} href="#" aria-label="Facebook">
                <Facebook />
              </IconButton>
              <IconButton color="inherit" component={Link} href="https://www.linkedin.com/in/munnawar-alam-shaikh-b2530a18b/" aria-label="LinkedIn">
                <LinkedIn />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
