import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import ConstImage from './grid/const.jpg';
import GlassImage from './grid/glass.jpg';
import MetalImage from './grid/metal.jpg';
import WoodImage from './grid/wood.jpg';
import InteriorImage from './grid/interior.jpg';
import PopImage from './grid/pop.jpg';

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  transition: 'transform 0.3s ease', // Add transition for smoother animation
  '&:hover': {
    transform: 'scale(1.05)', // Scale up the image on hover
  },
}));

const ImageWithText = ({ image, text }) => (
  <Item>
    <img src={image} alt="service image" style={{ width: '100%', height: 'auto', maxHeight: '350px', borderRadius: 8 }} />
    <Typography variant="h6" sx={{ paddingTop: '10px', fontSize: '25px' }}>
      {text}
    </Typography>
  </Item>
);

const Services = () => {
  return (
  
    <Box sx={{ py: 8 }}>
      <Typography variant="h2" align="center" gutterBottom sx={{ color: '#1976d2', fontFamily: 'Noto Sans', fontWeight: 'bold', fontSize: { xs: '28px', sm: '32px', md: '48px' } }}>
        Our Services
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ImageWithText image={ConstImage} text="Construction and Civil Work" />
            </Grid>
            <Grid item xs={12}>
              <ImageWithText image={GlassImage} text="Glass Work and Partition" />
            </Grid>
            <Grid item xs={12}>
              <ImageWithText image={MetalImage} text="Metal Art and Fabrication" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ImageWithText image={WoodImage} text="Wood Work and Furnitures" />
            </Grid>
            <Grid item xs={12}>
              <ImageWithText image={InteriorImage} text="Interior Decorator" />
            </Grid>
            <Grid item xs={12}>
              <ImageWithText image={PopImage} text="Pop and False Ceiling" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Services;
